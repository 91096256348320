.weDat-ft-elements-menu {
    text-align: center;
    position: absolute;
    bottom: 80px;
    top: 0;
    left: 0;
    width: 220px;
    margin: 32px 24px 32px 14px;
}

.weDat-ft-elements-list {
    position: absolute !important;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    scroll-behavior: smooth;
    padding-left: 10px !important;
}

.weDat-ft-elements-list .ant-list-item {
    border-bottom: none !important;
    width: 200px;
    padding: 4px 0;
}

.weDat-ft-elements-list .ant-list-item .ant-card {
    border-radius: 8px;
    text-align: left;
}

.weDat-ft-elements-list .ant-list-item .ant-card .ant-card-body {
    padding: 12px;
}

.weDat-ft-elements-list .ant-list-item .ant-card .ant-card-meta-title {
    text-overflow: clip;
    white-space: initial;
    font-weight: 400;
}

.weDat-ft-elements-list-img {
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
    margin-bottom: 5px;
}

.weDat-ft-scroll-down-btn-container,
.weDat-ft-scroll-up-btn-container {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 220px;
    height: 60px;
    z-index: 1000;
    pointer-events: none;
}

.weDat-ft-scroll-down-btn-container {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    margin-top: -60px;
    align-items: flex-end;
    bottom: 0;
}

.weDat-ft-scroll-up-btn-container {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    margin-bottom: -60px;
    align-items: flex-start;
}

.weDat-ft-scroll-down-btn,
.weDat-ft-scroll-up-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    background-color: #fff;
    font-size: 0px;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    border: 1px solid #d3dceb;
    pointer-events: auto;
}

.weDat-ft-scroll-down-btn {
    margin-bottom: -16px;
}

.weDat-ft-scroll-up-btn {
    margin-top: -16px;
}

.weDat-ft-scroll-down-btn:hover,
.weDat-ft-scroll-up-btn:hover {
    background-color: #f0f0f0;
}

/************ NO FAST-TRACK SVG *************/

.weDat-ft-elements-menu-noSVG .weDat-ft-elements-list {
    overflow-y: auto;
}

.weDat-ft-elements-menu-noSVG .ant-spin-container {
    margin: 16px 0 16px 8px;
    display: inline-block;
    text-align: left;
}

.weDat-ft-elements-menu-noSVG .ant-list-item {
    display: inline-flex;
    border-bottom: none !important;
    width: 200px;
    padding: 0;
    margin: 8px 8px;
}

.weDat-ft-elements-menu-noSVG .ant-list-item .ant-card {
    height: 110px;
}

.weDat-ft-elements-menu-noSVG .ant-list-item .ant-card .ant-card-meta-title {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

/*********** ELEMENTS MENU MODAL ************/

.weDat-ft-elementsMenuModal {
    max-width: 95%;
    margin-top: 90px !important;
}

.weDat-ft-elementsMenuModal .ant-modal-content {
    border-radius: 12px;
}

.weDat-ft-elementsMenuModal .ant-modal-header {
    border-radius: 12px 12px 0 0;
    border-bottom: none;
}

.weDat-ft-elementsMenuModal .ant-select {
    width: 90%;
}

.weDat-ft-elementsMenuModal .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
    align-items: center !important;
}

/* ============================================= */
/******************** MOBILE *********************/
/* ============================================= */

.weDat-ft-elements-menu-mobile {
    text-align: center;
    margin: 24px 0 21px 14px;
}

.weDat-ft-elements-list-mobile {
    padding-bottom: 2px !important;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
}

.weDat-ft-elements-list-mobile .ant-spin-container {
    display: flex;
}

.weDat-ft-elements-list-mobile .ant-list-item {
    border-bottom: none !important;
    height: 76px;
    margin-right: 8px;
    padding: 0;
}

.weDat-ft-elements-list-mobile .ant-list-item .ant-card {
    border-radius: 8px;
    text-align: left;
}

.weDat-ft-elements-list-mobile .ant-list-item .ant-card .ant-card-body {
    padding: 8px;
}

.weDat-ft-elements-list-mobile .ant-list-item .ant-card .ant-card-meta-title {
    text-overflow: clip;
    white-space: nowrap;
    font-weight: 400;
}

/************ NO FAST-TRACK SVG (MOBILE) *************/

.weDat-ft-elements-menu-noSVG-mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
}

.weDat-ft-elements-menu-noSVG-mobile .weDat-ft-elements-list-mobile  {
    overflow-y: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.weDat-ft-elements-menu-noSVG-mobile .weDat-ft-elements-list-mobile .ant-spin-container {
    display: block;
}

.weDat-ft-elements-menu-noSVG-mobile .weDat-ft-elements-list-mobile .ant-list-item {
    margin: 12px 8px;
}

/*********** ELEMENTS MENU DRAWER ************/

.weDat-ft-elementsMenuDrawer .ant-drawer-title {
    justify-content: left !important;
}

.weDat-ft-elementsMenuDrawer .ant-select {
    width: 100%;
}

.weDat-ft-elementsMenuDrawer .ant-list-item {
    padding: 12px 24px;
    text-align: left;
}

.weDat-ft-elementsMenuDrawer .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
    align-items: center !important;
}

.weDat-ft-elementsMenuDrawer-close {
    width: 100% !important;
    height: 48px !important;
    border-radius: 8px !important;
}
