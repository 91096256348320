.wedat-grapa-repair-position-description {
    font-size: large;
}

.wedat-grapa-repair-position-type-active {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    /* font-size: 0px; */
    /* padding: 12px; */
    width: 100%;
    height: 48px;
    border-radius: 8px;
    /* color: var(--weDat-grapa-toolbar-icon) Dust blue/800 *; */

    background-color: var(--weDat-grapa-toolbar-icon-active);
}

.wedat-grapa-repair-position-type {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    /* font-size: 0px; */
    /* padding: 12px; */
    width: 100%;
    height: 48px;
    border-radius: 8px;

    background: var(--weDat-grapa-background);
}

.wedat-grapa-repair-position-repairType {
    text-align: center;
    width: 100%;
    font-weight: bold;
    height: 24px;
}

.wedat-grapa-repair-position-WorkTime {
    text-align: center;
    width: 100%;
    /* font-weight: bold; */
    height: 24px;
}

.wedat-grapa-repair-position-SparePartPrice {
    /* text-decoration: line-through; */
    text-align: right;
    font-size: large;
    width: 100%;
    /* font-weight: bold; */
}
.wedat-grapa-repair-position-SparePartDiscount {
    text-align: right;
    width: 100%;
    /* font-weight: bold; */
}
.wedat-grapa-repair-position-ResultPrice {
    text-align: right;
    font-size: large;
    width: 100%;
    font-weight: bold;
}
