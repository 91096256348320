.materialLegendsWrapper .ant-modal-content {
    border-radius: 12px;
}

.materialLegendsWrapper .ant-modal-body {
    overflow-y: auto;
    max-height: 480px;
}

.materialLegends {
    width: 310px;
    max-width: 310px;
    max-height: 480px;
    position: absolute;
}

.materialLegendsMobile {
    width: 100%;
    max-width: 100%;
    max-height: 480px;
    margin: 0;
    position: absolute;
    left: auto;
    right: auto;
    top: auto;
    bottom: 0;
}

.materialLegend {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
}

.materialLegend svg {
    margin-top: 6px;
    margin-right: 15px;
    width: 16px;
    stroke-width: 1.8 !important;
}

/********** SCROLLBAR **********/

/* width */
.materialLegendsWrapper .materialLegends ::-webkit-scrollbar {
    width: 16px;
}

/* Track */
.materialLegendsWrapper .materialLegends ::-webkit-scrollbar-track {
    border-radius: 8px;
}

/* Handle */
.materialLegendsWrapper .materialLegends ::-webkit-scrollbar-thumb {
    background: #bbc2d1;
    border-radius: 8px;
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

/* Handle on hover */
.materialLegendsWrapper .materialLegends ::-webkit-scrollbar-thumb:hover {
    background: #7786a4;
    border-radius: 8px;
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}
