

.rimsEquipmentGridSelected {
    /* border: 2px solid blue; */
    border-radius: 3px;
    padding: 16px;
    background: #e6edfa;
    height: 100%
}



.rimsEquipmentGridNotSelected {
    /* border: 0px; */
    border-radius: 3px;
    padding: 16px;
    background: white;
    height: 100%
}