.pac-container {
    z-index: 10001;
    padding: 0;
    max-height: 400px;
    border-radius: 8px;
    box-shadow: 0px 8px 28px rgba(104, 119, 146, 0.21);
    overflow-x: hidden;
}

.pac-item,
.pac-item-query {
    height: 32px;
    font: 400 16px / 24px 'Noto Sans TC';
    color: rgb(50, 59, 76);
    display: flex;
    align-items: center;
}

.pac-container::after {
    width: 0;
    height: 0;
}
