.graphicTooltip {
    background-color: #454e5f;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 6px 8px;
    position: absolute;
    z-index: 1;
    white-space: pre-wrap;
    animation-name: showTooltip;
    animation-duration: 0.9s;
}

@keyframes showTooltip {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* =========== Arrow direction =========== */

/* To the left of the tooltip */
.leftTooltipArrow::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100% !important;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #454e5f transparent transparent;
}

/* To the right of the tooltip */
.rightTooltipArrow::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100% !important;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #454e5f;
}

/* At the top of the tooltip */
.topTooltipArrow::after {
    content: '';
    position: absolute;
    bottom: 100% !important;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #454e5f transparent;
}

/* At the bottom of the tooltip */
.bottomTooltipArrow::after {
    content: '';
    position: absolute;
    top: 100% !important;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #454e5f transparent transparent transparent;
}

/* =========== Arrow position =========== */

/* At the start of the tooltip side */
.start-bottomTooltipArrow::after,
.start-topTooltipArrow::after {
    left: 33%;
}
.start-leftTooltipArrow::after,
.start-rightTooltipArrow::after {
    top: 17px;
}

/* At the start of the tooltip side */
.center-bottomTooltipArrow::after,
.center-topTooltipArrow::after {
    left: 50%;
}
.center-leftTooltipArrow::after,
.center-rightTooltipArrow::after {
    top: 50%;
}

/* At the start of the tooltip side */
.end-bottomTooltipArrow::after,
.end-topTooltipArrow::after {
    left: 66%;
}
.end-leftTooltipArrow::after,
.end-rightTooltipArrow::after {
    top: 90%;
}
