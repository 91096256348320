/* .ant-btn-icon-only > span {
    font-size: 16px !important;
} */

:root {
    --widthLeftPanel: 400px;
    --widthRightPanel: 450px;
}

.fastMovingBar .ant-btn,
.bottomLeftMainButtonBar .ant-btn,
.bottomRightMainButtonBar .ant-btn,
.graphicDamageSelectionBodyLeftMenu .ant-btn {
    border: none;
}

.graphicDamageSelectionBody {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /* right: 84px; */
    /* margin-right: 84px; */
    /* padding-right: 96px; */
    bottom: 0;
    text-align: center;
    /* background-color: #f0f2f5; */
    transition: var(--weDat-grapa-drawers-transition);
}

.graphicDamageSelectionBody-mobile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /* right: 84px; */
    /* margin-right: 84px; */
    padding-right: 52px;
    bottom: 0;
    text-align: center;
    /* background-color: #f0f2f5; */
    transition: var(--weDat-grapa-drawers-transition);
}

.graphicDamageSelectionBodyLeftMenu {
    left: var(--widthLeftPanel);
}

.graphicDamageSelectionBodyRightMenu {
    right: var(--widthRightPanel);
}
.graphicDamageSelectionBodyLeftRightMenu {
    left: var(--widthLeftPanel);
    right: var(--widthRightPanel);
}

.mainButtonBar {
    text-align: left;
    user-select: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1010;

    /* background-color: rgba(0, 21, 41, 0); */

    padding: 8px;
}

.buttonMainBar {
    margin: 2px;
}

.bottomMainButtonBar {
    text-align: left;
    user-select: none;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1010;

    background-color: rgba(0, 21, 41, 0);

    padding: 8px;
}

.topLeftMainButtonBar {
    text-align: left;
    user-select: none;
    position: absolute;
    max-width: 40%;
    left: 0;
    top: 0;
    /* right: 0; */
    z-index: 1010;

    background-color: rgba(0, 21, 41, 0);

    padding: 6px;
    text-align: left;
}

.bottomLeftMainButtonBar {
    text-align: left;
    user-select: none;
    position: absolute;
    max-width: 100%;
    /* max-width: 52px; */
    left: 0;
    bottom: 0;
    /* right: 0; */
    z-index: 1010;

    background-color: rgba(255, 255, 255, 0.95);

    /* padding: 8px; */
    text-align: left;
}

.bottomRightMainButtonBar {
    /* text-align: left; */
    user-select: none;
    position: absolute;
    /* left: 0; */
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1010;
    /* max-width: 60%; */
    max-width: 72px;
    background-color: rgba(255, 255, 255, 0);
    /* background-color: rgba(255, 255, 255, 0.95); */

    /* padding: 12px; */
    /* text-align: right; */
    /* margin: 0px 8px 0px 0px; */
}

.fastMovingBar {
    text-align: left;
    user-select: none;
    position: absolute;
    /* max-width: 40%; */
    /* left: 0; */
    /* bottom: 42px; */
    /* bottom: 80px; */
    /* top: 0; */
    /* right: 0; */
    /* z-index: 1010; */

    right: 0;
    top: 118px;

    background-color: rgba(255, 255, 255, 0.95);

    padding: 8px;
    text-align: left;
}

.offsetMainButtonBarLeftMenu {
    left: calc(var(--widthLeftPanel) + 0px);
}

.offsetMainButtonBarRightMenu {
    right: calc(var(--widthRightPanel) + 0px);
}

.mainButtonBarLeftMenu {
    padding-left: calc(var(--widthLeftPanel) + 8px);
}

.mainButtonBarRightMenu {
    padding-right: calc(var(--widthRightPanel) + 8px);
}

.mainButtonBarLeftRightMenu {
    padding-left: calc(var(--widthLeftPanel) + 8px);
    padding-right: calc(var(--widthRightPanel) + 8px);
}
