.weDat-ft-summaryPanelMin {
    margin: 24px 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 72px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 16px 0px 0px 16px;
}

.weDat-ft-summaryPanelMin button {
    margin-top: 24px;
}

.weDat-ft-summaryPanelMin h2 {
    margin-top: 55px;
}

.weDat-ft-summaryPanelMin .rotated {
    transform: rotate(-90deg);
}

.weDat-ft-summaryPanelMin .weDat-ft-summaryPanelMin-bottom {
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
}

.weDat-ft-summaryPanelMin .weDat-ft-calcResult {
    text-align: left;
    margin: 0 0 30px 0;
}
