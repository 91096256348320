.weDat-grapa-inline-icon {
    display: flex;
    justify-content: center;
    align-items: end;
    user-select: none;

    /* font-size: 0px; */
    padding: 4px;
    border-radius: 8px;
    color: var(--weDat-grapa-toolbar-icon) /* Dust blue/800 * */;
}

.weDat-grapa-inline-icon-box {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.weDat-grapa-inline-icon:hover {
    color: var(--weDat-grapa-toolbar-icon-onHover); /* Text/Link hover */
}

.weDat-grapa-inline-icon:active {
    color: var(--weDat-grapa-toolbar-icon-onActive); /* Text/Link inactive */
    /* background-color: #e3f2ff; */
}

.weDat-grapa-inline-icon-active {
    background-color: var(--weDat-grapa-toolbar-icon-active);
}
