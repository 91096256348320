.wedat-grapa-repair-summaries-header {
    text-align: right;
    /* font-weight: bold; */
    font-size: large;
}

.wedat-grapa-repair-summaries-label {
    text-align: right;
    font-size: large;
}

.wedat-grapa-repair-summaries-ResultPrice {
    text-align: right;
    font-size: large;
    width: 100%;
    font-weight: bold;
}

.wedat-grapa-repair-summaries-ResultPrice-notActual {
    text-align: right;
    font-size: large;
    width: 100%;
    font-weight: bold;
    text-decoration: line-through;
}
