.weDat-grapa-tool-bar {
    border-radius: 8px;
    user-select: none;
    /* background-color: var(--weDat-grapa-background); */
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 12px;
}

.weDat-grapa-tool-bar-mobile {
    user-select: none;
    /* background-color: var(--weDat-grapa-background); */
    background: var(--weDat-grapa-button-group-bar-background);
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: 0px;
}

.weDat-grapa-tool-bar-direction-row {
    flex-direction: row;
}
