:root {
    --widthFtSummaryPanel: 505px;
    --widthFtSummaryPanelMin: 72px;

    --weDat-ft-toolbar-icon: '#687792';
    --weDat-ft-toolbar-icon-onHover: '#2594fe';
    --weDat-ft-toolbar-icon-onActive: '#bcdeff';
    --weDat-ft-toolbar-icon-active: '#e3f2ff';
}

#weDat-ft-root {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    margin: auto;
    position: relative;
    overflow: hidden;
}

#weDat-ft-root .ant-btn-icon-only.ant-btn-lg {
    height: 48px;
    width: 48px;
    border-radius: 8px;
}

.weDat-ft-body {
    margin: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-self: center;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 0px 24px 24px 24px;
    user-select: none;
}

.weDat-ft-body-summaryPanel {
    right: var(--widthFtSummaryPanel);
}

.weDat-ft-body-summaryPanelMin {
    right: var(--widthFtSummaryPanelMin);
}

.weDat-ft-main-btn {
    background-color: #103366 !important;
    border: 1px solid #103366 !important;
    color: #ffffff !important;
}

.weDat-ft-main-btn:hover,
.weDat-ft-main-btn:active {
    border-color: #2594fe !important;
    background-color: #2594fe !important;
    color: #ffffff !important;
}

/********** MOBILE **********/

.weDat-ft-body-mobile {
    position: absolute;
    top: 0;
    bottom: 70px;
    left: 0;
    right: 0;
    justify-self: center;
    overflow: hidden;
    background-color: #ffffff;
    user-select: none;
}
