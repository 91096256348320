.weDat-ft-fullHeight {
    height: 100% !important;
}

.weDat-ft-aiGallery-container {
    position: absolute;
    bottom: 70px;
    top: 0;
    right: 0;
    left: 50%;
    transform: translate(-50%);
    padding: 36px 24px 0 24px;
    width: 100%;
}

.weDat-ft-aiGallery-container > div {
    height: 100%;
}

.weDat-ft-aiGallery-container > div > div:last-child {
    max-height: calc(100% - 52px);
    height: calc(100% - 52px);
}

.weDat-ft-aiGallery-container .swiper-container:not(.swiper-container-thumbs) .swiper-wrapper {
    border-radius: 12px;
}

.weDat-ft-aiGallery-container .swiper-container:not(.swiper-container-thumbs) .swiper-slide img {
    border-radius: 12px;
    object-fit: contain;
    object-position: top;
}

.weDat-ft-aiGallery-container .swiper-container:not(.swiper-container-thumbs) .swiper-slide {
    width: 100% !important;
}

.weDat-ft-aiGallery-container .swiper-container:not(.swiper-container-thumbs) .swiper-slide div {
    height: 100%;
}

.weDat-ft-aiGallery-container .swiper-container-thumbs {
    height: 76px !important;
    max-width: 1000px;
}

.weDat-ft-aiGallery-container .swiper-container-thumbs .swiper-wrapper {
    height: 56px;
}

.weDat-ft-aiGallery-container .swiper-container-thumbs .swiper-slide {
    border-radius: 8px;
    width: 75px !important;
    opacity: 1 !important;
}

.weDat-ft-aiGallery-container .swiper-container-thumbs .swiper-slide img {
    border-radius: 8px;
}

.weDat-ft-aiGallery-container .swiper-container-thumbs .swiper-slide-thumb-active {
    background: url('../CommonComponents/Icons/EyeIcon.svg') rgba(37, 116, 219, 0.6) center center no-repeat;
    transform-style: preserve-3d;
}

.weDat-ft-aiGallery-container .swiper-container-thumbs .swiper-slide-thumb-active img {
    transform: translateZ(-10px);
}

.weDat-ft-aiGallery-container > div > div:first-child {
    margin-bottom: 24px;
    justify-content: end;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.weDat-ft-aiGallery-container > div > div:first-child > label > div {
    background-color: #edf0fc;
}

.weDat-ft-aiGallery-container > div > div:first-child > label > div::before {
    background-color: #a0acc4;
}

.weDat-ft-aiGallery-container > div > div:first-child > label > input:checked + div {
    background-color: #e3f2ff;
}

.weDat-ft-aiGallery-container > div > div:first-child > label > span {
    color: #323b4c;
}

.weDat-ft-aiGallery-container .swiper-button-prev,
.weDat-ft-aiGallery-container .swiper-button-next {
    border: 1px solid #d3dceb;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    top: 58%;
}

.weDat-ft-aiGallery-container .swiper-button-prev {
    background: url('../CommonComponents/Icons/ChevronLeftIcon.svg') #ffffff center center no-repeat;
}

.weDat-ft-aiGallery-container .swiper-button-next {
    background: url('../CommonComponents/Icons/ChevronRightIcon.svg') #ffffff center center no-repeat;
}

.weDat-ft-aiGallery-container .swiper-button-prev:after,
.weDat-ft-aiGallery-container .swiper-button-next:after {
    content: none;
}

.weDat-ft-aiGallery-container .swiper-button-prev.swiper-button-disabled,
.weDat-ft-aiGallery-container .swiper-button-next.swiper-button-disabled {
    display: none;
}

/* ============================================= */
/******************** MOBILE *********************/
/* ============================================= */

.weDat-ft-aiGallery-container-mobile {
    position: absolute;
    top: 30px;
    bottom: 70px;
    left: 0;
    right: 0;
}

.weDat-ft-aiGallery-container-mobile > div {
    height: 100%;
}

.weDat-ft-aiGallery-container-mobile > div > div:last-child {
    max-height: calc(100% - 52px);
    height: calc(100% - 52px);
}

.weDat-ft-aiGallery-container-mobile .swiper-container:not(.swiper-container-thumbs) .swiper-slide {
    width: 100% !important;
}

.weDat-ft-aiGallery-container-mobile .swiper-container:not(.swiper-container-thumbs) .swiper-slide > div {
    height: 100%;
}

.weDat-ft-aiGallery-container-mobile .swiper-container:not(.swiper-container-thumbs) .swiper-slide > div img {
    object-fit: contain;
    object-position: top;
}

.weDat-ft-aiGallery-container-mobile .swiper-container-thumbs .swiper-wrapper {
    height: 56px;
    margin-left: 12px;
}

.weDat-ft-aiGallery-container-mobile .swiper-container-thumbs .swiper-slide {
    border-radius: 8px;
    width: 75px !important;
    opacity: 1 !important;
}

.weDat-ft-aiGallery-container-mobile .swiper-container-thumbs .swiper-slide img {
    border-radius: 8px;
}

.weDat-ft-aiGallery-container-mobile .swiper-container-thumbs .swiper-slide-thumb-active {
    background: url('../CommonComponents/Icons/EyeIcon.svg') rgba(37, 116, 219, 0.6) center center no-repeat;
    transform-style: preserve-3d;
}

.weDat-ft-aiGallery-container-mobile .swiper-container-thumbs .swiper-slide-thumb-active img {
    transform: translateZ(-10px);
}

.weDat-ft-aiGallery-container-mobile > div > div:first-child {
    margin: 0 0 24px 12px;
    justify-content: start;
}

.weDat-ft-aiGallery-container-mobile > div > div:first-child > label > div {
    background-color: #edf0fc;
}

.weDat-ft-aiGallery-container-mobile > div > div:first-child > label > div::before {
    background-color: #a0acc4;
}

.weDat-ft-aiGallery-container-mobile > div > div:first-child > label > input:checked + div {
    background-color: #e3f2ff;
}

.weDat-ft-aiGallery-container-mobile > div > div:first-child > label > span {
    color: #323b4c;
}

.weDat-ft-aiGallery-container-mobile .swiper-button-prev,
.weDat-ft-aiGallery-container-mobile .swiper-button-next {
    display: none;
}
