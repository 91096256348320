:root {
    --weDat-grapa-drawers-transition: left 0.2s, right 0.2s, transform 0.2s, box-shadow 0.3s;
}

.ant-drawer-body {
    padding: 0px;
    /* background-color: rgb(0, 21, 41); */
    /* overflow-y: scroll; */
}

.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-left: 16px;
}

.weDat-grapa-root #dat-logo {
    visibility: hidden;
}

.graphicDamageSelectionBody g #dat-logo {
    visibility: hidden;
}

.ant-drawer-content-wrapper {
    max-width: 100%;
    transition: var(--weDat-grapa-drawers-transition);
}

.weDat-grapa-root .ant-btn-lg {
    height: 48px;
    border-radius: 8px;
}

.weDat-grapa-root .ant-btn-icon-only.ant-btn-lg {
    height: 48px;
    width: 48px;
    border-radius: 8px;
}

.weDat-grapa-root .ant-collapse {
    border-radius: 8px;
}

.weDat-grapa-root .ant-collapse-header {
    height: 48px;
}

.weDatRadioButton {
    width: 100%;
}

.weDatRadioButton .ant-radio-button-wrapper {
    /* height: 48px; */
    overflow: hidden;
    text-overflow: ellipsis;
    width: 33.333%;
    text-align: center;
    /* padding: 2px 8px 2px 8px; */
}

.weDatRadioButton .ant-radio-button-wrapper:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.weDat-grapa-root .ant-radio-button-wrapper .ant-btn-lg {
    height: 40px;
}

.weDat-grapa-root .ant-radio-button-wrapper:focus-within {
    box-shadow: none;
}

.weDat-grapa-root
    .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
}

.weDatRadioButton .ant-radio-button-wrapper:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
