.DATElement {
    fill-opacity: 50%;
}

/* // ===============================================   materials */

/* svgMaterialAttributeColors */

/* svgMetalColor:      "#8F8F8F",
//svgGlassColor:      "#44CCFF",
svgGlassColor:      "#0066FF",
svgBrakeLightColor: "#FF0000" */

.materialMetal {
    fill: #8f8f8f !important;
    fill-opacity: 70%;
}

.materialGlass {
    fill: #86A8D0 !important;
    fill-opacity: 70%;
}

.materialBrakeLight {
    fill: #ff0000 !important;
    fill-opacity: 70%;
}

/* // "8001":         "#FFFF00", // plastic */
.materialPlastic {
    /* fill: #FFFD80 !important;
    fill-opacity: 70%; */
    fill: #FFE675 !important;
    fill-opacity: 70%;
}
/* // "8002":         "#FFD700", // glass fibre */
.materialGlassFibre {
    fill: #FFE675 !important;
    fill-opacity: 70%;
}
/* // "8003":         "#1F1F1F", // carbon */
.materialCarbon {
    fill: #ABAEB3 !important;
    fill-opacity: 70%;
}
/* // "8004":         "#FFFF00", // plastic without text */
.materialPlasticWithoutText {
    fill: #FFE675 !important;
    fill-opacity: 70%;
}
/* // "8005":         "#E066FF", // hardened steel */
.materialHardenedSteel {
    fill: #E096C6 !important;
    fill-opacity: 70%;
}
/* // "8006":         "#90EE90", // light metal */
.materialLightMetal {
    fill: #C9F6CD !important;
    fill-opacity: 70%;
}
/* // "8007":         "#00B050", // aluminium */
.materialAluminium {
    fill: #85CDB3 !important;
    fill-opacity: 70%;
}
/* // "8008":         "#008B00", // magnesium */
.materialMagnesium {
    fill: #79B27C !important;
    fill-opacity: 70%;
}
/* // "8009":         "#E26B0A", // titan*/
.materialTitan {
    fill: #E3A26F !important;
    fill-opacity: 70%;
}
/* // "8010":         "#EECFA1", // internal covers */
.materialInternalCovers {
    fill: #F7E7D0 !important;
    fill-opacity: 70%;
}
/* // "8011":         "#FF9922"  // high voltage */
.materialHighVoltage {
    fill: #F9C986 !important;
    fill-opacity: 70%;
}

/* // =============================================== selectede */

.leftRighStSelectedElements {
    stroke-width: 0.8 !important;
    stroke: #48ACE4 !important;
    /* z-index: 10000 !important; */
}

.leftStSelectedElements {
    stroke-width: 0.8 !important;
    stroke: #5DF06A !important;
    /* z-index: 10000 !important; */
}

.rightStSelectedElements {
    stroke-width: 0.8 !important;
    stroke: #DD0000 !important;
    /* z-index: 10000 !important; */
}

/* // =============================================== */

.selectedElement {
    /* fill: rgb(0, 0, 0, 0.7) !important;
    stroke-width: 0.7 !important;
    fill-opacity: 70%; */

    fill: black !important;
    fill-opacity: 40%;
    filter: drop-shadow(0px 1px 0.5px rgb(0 0 0 / 0.7));
}

/* .selectedElement:hover {
    fill: blue !important;
    fill-opacity: 70%;
} */

.selectableElement:hover {
    stroke-width: 1.3 !important;
    fill: black !important;
    fill-opacity: 40%;
    pointer-events: all;

    stroke-opacity: 80% !important;
    stroke: black !important;

    /* stroke-width: 0.8 !important; */
    /* stroke: black !important; */
    /* z-index: 10000 !important; */
    /* -webkit-box-shadow: -5px -5px 88px 9px rgba(34, 60, 80, 0.6);
    -moz-box-shadow: -5px -5px 88px 9px rgba(34, 60, 80, 0.6);
    box-shadow: -5px -5px 88px 9px rgba(34, 60, 80, 0.6); */
    /* -webkit-filter: drop-shadow( 3px 5px 2px rgb(0 0 0 / 0.4)); */
    filter: drop-shadow(0px 1px 0.5px rgb(0 0 0 / 0.7));
    /* transition: transform 0.1s; */
    /* transform: scale(1);  */
    /* transform: scale(1.2); */
    /* transition: all 1s; */
    /* transform-origin: 50% 50%; */
}

.childWithoutEvent {
    pointer-events: none;
    fill-opacity: 0.9;
}

.deSelectedElement {
    fill: none !important;
    fill-opacity: 70%;
}

/* // =============================================== */

.unfilteredElement {
    /* fill: rgba(255, 255, 255, 0.1) !important; */
    /* fill: rgba(255, 255, 255, 0.9) !important; */
    /* stroke: rgba(255, 255, 255, 0.9) !important; */
    /* stroke-width: 0.3 !important; */
    fill-opacity: 5% !important;
    stroke-opacity: 5% !important;
    /* stroke: rgba(0, 0, 0, 0.1) !important; */
    /* z-index: 10000 !important; */
}

.unfilteredElementDatId {
    /* fill: rgba(255, 255, 255, 0.1) !important; */
    fill: #edeff2 !important;
    /* stroke: rgba(255, 255, 255, 0.9) !important; */
    stroke: #edeff2 !important;
    /* stroke-width: 0.3 !important; */
    fill-opacity: 80% !important;
    stroke-opacity: 80% !important;
    /* stroke: rgba(0, 0, 0, 0.1) !important; */
    /* z-index: 10000 !important; */
}
