.weDat-ft-toolbar-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    font-size: 0px;
    padding: 12px;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    color: var(--weDat-ft-toolbar-icon);
}

.weDat-ft-toolbar-icon:hover {
    color: var(--weDat-ft-toolbar-icon-onHover);
}

.weDat-ft-toolbar-icon:active {
    color: var(--weDat-ft-toolbar-icon-onActive);
}

.weDat-ft-toolbar-icon-active {
    background-color: var(--weDat-ft-toolbar-icon-active);
}
