.weDat-grapa-button-group-bar {
    user-select: none;
    /* Adding details */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
    gap: 4px;

    /* width: 72px; */
    /* height: 228px; */

    /* White */

    /* background: #ffffff; */
    opacity: 0.95;
    background: var(--weDat-grapa-button-group-bar-background);
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;

    /* margin: 16px; */
}

.weDat-grapa-button-group-bar:hover {
    opacity: 1;
}

.weDat-grapa-button-group-bar-mobile {
    user-select: none;
    /* Adding details */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    /* flex-direction: row; */
    align-items: flex-start;
    padding: 2px;
    gap: 2px;

    /* width: 52px; */
    /* height: 228px; */

    /* White */

    /* background: #ffffff; */
    background: var(--weDat-grapa-button-group-bar-background);
    border-radius: 0px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;

    /* margin: 16px; */
}

.weDat-grapa-button-group-bar-direction-row {
    flex-direction: row;
}
