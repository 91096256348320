.zoneGraphics1047 > image {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    opacity: 0.6;
}

.zoneGraphics1047 g[id^='DATID_'] path {
    fill: white;
    fill-opacity: 0;
    stroke: gray;
}

.zoneGraphics1047 g[id^='DATID_'] path:hover {
    /* <!--stroke-width: 3 !important;--> */
    /* <!--stroke: blue !important;--> */
    /* fill: black; */
    /* fill: #103366; */
    /* fill: #ff7722; */
    /* fill-opacity: 0.6; */

    fill: var(--ant-primary-color) !important;
    fill-opacity: 0.7 !important;
}

/* .zoneGraphics1047 .selectedGroupZone1077 g[id^='DATID_'] path  { */
/* .zoneGraphics1047 .selectedGroupZone1077 > g > path { */
.selectedGroupZone1077 {
    /* <!--stroke-width: 3 !important;--> */
    /* <!--stroke: blue !important;--> */
    /* fill: red !important; */
    /* fill: #ff7722 !important; */
    fill: #00ccff !important;
    /*fill-opacity: 0.4 !important; */
    /* fill: #4bafff!important; */
    /* fill: rgb(16, 51, 102) !important; */
    /* fill: #9fd3eb !important; */
    fill-opacity: 0.5 !important;
}

.selectedGroupZone1077:hover {
    /* <!--stroke-width: 3 !important;--> */
    /* <!--stroke: blue !important;--> */
    /* fill: red !important; */
    /* fill: #ff7722 !important; */
    /* fill: #103366 !important; */
    /* fill: rgb(16, 51, 102) !important; */
    /* fill-opacity: 0.6 !important; */

    fill: var(--ant-primary-color) !important;
    fill-opacity: 0.7 !important;
}

.selectedGroupZone1077withPosition {
    /* <!--stroke-width: 3 !important;--> */
    /* <!--stroke: blue !important;--> */
    /* fill: red !important; */
    /* stroke-width: 7 !important; */
    /* fill: #ff7722 !important; */
    /*fill-opacity: 0.4 !important; */
    fill: #ac98b0 !important;
    /* fill: rgb(16, 51, 102) !important; */
    /* fill: #9fd3eb !important; */
    fill-opacity: 0.7 !important;
}

.selectedGroupZone1077withPosition:hover {
    /* <!--stroke-width: 3 !important;--> */
    /* <!--stroke: blue !important;--> */
    /* fill: red !important; */
    /* fill: #ff7722 !important; */
    /* fill: #103366 !important; */
    /* fill: rgb(16, 51, 102) !important; */
    /* fill-opacity: 0.6 !important; */

    fill: var(--ant-primary-color) !important;
    fill-opacity: 0.7 !important;
}

.withPositionsZone {
    /* <!--stroke-width: 3 !important;--> */
    /* <!--stroke: blue !important;--> */
    /* fill: red !important; */
    /* fill: #ff7722 !important; */
    /* fill: rgb(16, 51, 102) !important; */
    /* fill: #ff7722 !important; */
    /* fill: #9fa4ad !important; */
    /* fill-opacity: 0.2 !important; */
    /* fill-opacity: 0.6 !important; */

    fill: #1890ff !important;
    /* fill: #00CCFF !important; */
    /* fill: var(--ant-primary-color) !important; */
    fill-opacity: 0.5 !important;
}

.withPositionsZone:hover {
    /* <!--stroke-width: 3 !important;--> */
    /* <!--stroke: blue !important;--> */
    /* fill: red !important; */
    /* fill: rgb(16, 51, 102) !important; */
    /* fill: #ff7722 !important; */
    /* fill: #ff7722 !important; */
    /* fill-opacity: 0.6 !important; */

    fill: var(--ant-primary-color) !important;
    fill-opacity: 0.7 !important;
}
