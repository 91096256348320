.weDat-ft-main-svg {
    position: absolute;
    left: 200px;
    bottom: 80px;
    margin: 36px 24px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.weDat-ft-graphic-view {
    position: absolute !important;
    bottom: 24px;
    left: 24px;
    right: 24px;
}

.weDat-ft-graphic-view .ant-btn {
    height: 36px;
}

.weDat-ft-graphic-view > .ant-btn:first-child:not(:last-child) {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.weDat-ft-graphic-view > .ant-btn:last-child:not(:first-child) {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

/* ============================================= */
/******************** MOBILE *********************/
/* ============================================= */

.weDat-ft-main-svg-mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.weDat-ft-main-svg-mobile object {
    display: table;
}

.weDat-ft-graphic-view-mobile {
    width: 100%;
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.weDat-ft-graphic-view-mobile .ant-btn:hover {
    background-color: #fff;
}

.weDat-ft-graphic-view-mobile .ant-select-selector {
    width: 200px !important;
    height: 48px !important;
    border-radius: 8px !important;
    align-items: center !important;
}

.weDat-ft-graphic-view-select {
    width: 180px;
    height: 48px;
    font-size: 16px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px #d3dceb solid;
    border-radius: 8px;
    position: relative;
}

.weDat-ft-graphic-view-select label {
    font-size: 12px;
    position: absolute;
    top: -10px;
    z-index: 1;
    left: 8px;
    background-color: white;
    padding: 0 4px;
    color: #8b98b3;
}

/******** GRAPHIC VIEW SELECT MODAL *********/

.weDat-ft-graphicView-modal {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding-bottom: 0 !important;
    position: absolute !important;
    left: 0 !important;
    right: auto !important;
    top: auto !important;
    bottom: 0 !important;
}

.weDat-ft-graphicView-modal .ant-modal-content {
    border-radius: 12px 12px 0 0;
    padding-bottom: 24px;
}

.weDat-ft-graphicView-modal .ant-modal-header {
    border-radius: 12px 12px 0 0;
}

.weDat-ft-graphicView-modal .ant-modal-body {
    padding: 0;
}

.weDat-ft-graphicView-modal .graphicView {
    height: 49px;
    border-top: 1px #edf0fc solid;
    display: flex;
    align-items: center;
    padding: 0 24px;
    font-size: 16px;
}

.weDat-ft-graphicView-modal .graphicView:hover {
    background-color: #bcdeff;
}
