.weDat-ft-calcResult-wrapper {
    width: 100%;
    display: flex;
    height: 70px;
    align-items: center;
}

.weDat-ft-calcResult-wrapper .ant-btn,
.weDat-ft-calcResult-wrapper-mobile .ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
}

.weDat-ft-calculate-btn {
    width: 150px !important;
    height: 48px !important;
    border-radius: 8px !important;
}

.weDat-ft-calcResult {
    margin-right: auto;
    margin-left: 10px;
}

.weDat-ft-calcResult-header {
    font-size: 14px;
    color: #8b98b3;
    white-space: nowrap;
}

.weDat-ft-calcResult-price {
    font-weight: bold;
    font-size: large;
}

.weDat-ft-calcElement {
    background-color: #f0f2f5;
    text-align: center;
    width: 90%;
    border-radius: 8px;
    padding: 5px 0;
}

.weDat-ft-calcElement div {
    font-weight: bold;
}

/************ DAMAGE LIST ************/
.weDat-ft-list-damage thead th {
    background-color: #fff !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #687792 !important;
}

.weDat-ft-list-damage thead th::before {
    content: none !important;
}

.weDat-ft-list-damage .ant-table-title {
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
}

/************ RESULT TABLE ************/
.weDat-ft-resultTable {
    margin-top: 30px !important;
}

.weDat-ft-resultTable .ant-list-item {
    display: flex;
    font-weight: bold;
}

/************** MOBILE *************/
.weDat-ft-summary-panel-footer-mobile {
    z-index: 1000;
    width: 100%;
    background-color: #ffffff;
}

.weDat-ft-summary-panel-footer-mobile .ant-col {
    text-align: center;
    text-align: -webkit-center;
}

.weDat-ft-calcResult-wrapper-mobile {
    display: flex;
    height: 70px;
    align-items: center;
    border-top: 1px #d3dceb solid;
}

.weDat-ft-body-mobile .ant-drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.weDat-ft-body-mobile .ant-drawer-footer {
    margin-top: 1px;
}
