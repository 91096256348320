.weDat-ft-damageSelectedModal {
    max-width: 20em !important;
}

.weDat-ft-damageSelectedModal .ant-modal-content {
    border-radius: 12px;
}

.weDat-ft-damageSelectedModal .ant-modal-header {
    border-radius: 12px 12px 0 0;
    border-bottom: none;
}

.weDat-ft-damageSelectedModal .ant-modal-body {
    padding: 0;
}

.weDat-ft-damageSelectedModal .ant-list-item {
    padding: 12px 24px;
    cursor: pointer;
    display: flex;
}

.weDat-ft-damageSelectedModal .ant-list-item:hover {
    background: #fafafa;
}

/* ============================================= */
/******************** MOBILE *********************/
/* ============================================= */

.weDat-ft-damageSelectedModal-mobile {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding-bottom: 0 !important;
    position: absolute !important;
    left: 0 !important;
    right: auto !important;
    top: auto !important;
    bottom: 0 !important;
}

.weDat-ft-damageSelectedModal-mobile .ant-modal-content {
    padding-bottom: 24px;
    border-radius: 12px 12px 0 0;
}

.weDat-ft-damageSelectedModal-mobile .ant-modal-header {
    border-radius: 12px 12px 0 0;
}

.weDat-ft-damageSelectedModal-mobile .ant-modal-body {
    padding: 0;
}

.weDat-ft-damageSelectedModal-mobile .ant-list-item {
    height: 49px;
    padding: 12px 24px;
    font-size: 16px;
}
