.weDat-grapa-splitter-button-group-bar {
    display: flex;
    order: 2;

    width: 100%;
    height: 1px;

    border-width: 0px 4px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);

    /* background-color: var(--weDat-grapa-background); */
    background-color: #d3dceb;
}
