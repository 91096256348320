.weDat-grapa-toolbar-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    font-size: 0px;
    padding: 12px;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    color: var(--weDat-grapa-toolbar-icon) /* Dust blue/800 * */;
}

.weDat-grapa-toolbar-icon:hover {
    color: var(--weDat-grapa-toolbar-icon-onHover); /* Text/Link hover */
}

.weDat-grapa-toolbar-icon:active {
    color: var(--weDat-grapa-toolbar-icon-onActive); /* Text/Link inactive */
    /* background-color: #e3f2ff; */
}

.weDat-grapa-toolbar-icon-active {
    background-color: var(--weDat-grapa-toolbar-icon-active);
}
